
















































































































































































































































































































































import Component, { mixins } from "vue-class-component";
import { namespace } from "vuex-class";
import { TEST_EDITOR_MODULE } from "@/constants/storeNames";
import { QuestionKitsType, TaskType } from "@/types/API/QuestionKitsType";
import { TEST_STATUS_CONFIG } from "@/config/testStatusConfig";
import { TestStatusConfigType } from "@/types/TestStatusConfigType";
import { DateFormatPreview } from "@/services/DateFormat/DateFormatPreview";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";
import Loader from "@/components/UI/Loader.vue";
import InputField from "@/components/form/InputField.vue";
import TaskPreview from "@/components/editor/TaskPreview.vue";
import TaskEditor from "@/components/editor/TaskEditor.vue";
import Pagination from "@/components/UI/Pagination.vue";
import Modal from "@/components/UI/Modal.vue";
import { SetStatusDataType } from "@/types/API/SetStatusDataType";
import { app_routes } from "@/router/app_routes";
import { QuestionKitsMixin } from "@/mixins/QuestionKitsMixin";
import { TASK_EMPTY_DATA } from "@/config/taskEmptyData";
import { HttpErrorListType } from "@/types/API/HttpErrorType";
import { StoreActionType } from "@/types/StoreTypes";
import * as STATUSES from "@/constants/testStatuses";

const testEditor = namespace(TEST_EDITOR_MODULE);

@Component({
  components: {
    MaterialIcon,
    TaskPreview,
    TaskEditor,
    InputField,
    Pagination,
    Loader,
    Modal,
  },
  filters: {
    questionsTranslate(count: number): string {
      const ONE_ELEMENT = 1;
      const SOME_ELEMENTS = 4;

      if (ONE_ELEMENT <= count && count <= SOME_ELEMENTS) {
        return "запитання";
      }

      return "запитань";
    },
    formatDate(dateStr?: string): string {
      return dateStr ? DateFormatPreview.getFullDate(dateStr) : "–";
    },
  },
})
export default class TestEdit extends mixins(QuestionKitsMixin) {
  @testEditor.State updatingQuestionKits!: boolean;
  @testEditor.State data!: QuestionKitsType;
  @testEditor.State loading!: boolean;
  @testEditor.State validateErrors!: HttpErrorListType;

  // search question field
  public searchQuestionField = "";
  // question kits statuses
  public statusActive = STATUSES.STATUS_ACTIVE;
  public statusArchived = STATUSES.STATUS_ARCHIVED;
  // pagination
  public page = 1; // current page
  public readonly perPage = 20; // elements on the page
  // toggle visible modals
  public showDraftModal = false;
  public showActiveModal = false;
  public showCloneTestModal = false;
  public showEditQuestionModal = false;
  public showDeleteQuestionModal = false;
  public showDeleteTestModal = false;
  // edit question
  public readonly defaultTaskData: TaskType = TASK_EMPTY_DATA;
  public editQuestion: TaskType = TASK_EMPTY_DATA;

  get getFilteredQuestionsList(): TaskType[] {
    const regex = new RegExp(this.searchQuestionField);
    return this.data.questions.filter(({ title }) => regex.test(title));
  }

  get getStatusConfig(): TestStatusConfigType | null {
    return this.data ? TEST_STATUS_CONFIG[this.data.status] : null;
  }

  get getQuestionsList(): TaskType[] {
    const DELTA = 1;

    return this.getFilteredQuestionsList.slice(
      (this.page - DELTA) * this.perPage,
      this.page * this.perPage
    );
  }

  get isDraftStatus(): boolean {
    return this.data.status === STATUSES.STATUS_DRAFT;
  }

  get isActiveStatus(): boolean {
    return this.data.status === STATUSES.STATUS_ACTIVE;
  }

  async mounted(): Promise<void> {
    const { id } = this.$route.params;
    await this.getData(id);
  }

  @testEditor.Action cloneActiveTest!: () => StoreActionType<number>;
  @testEditor.Action getData!: (id: string) => Promise<void>;
  @testEditor.Action updateQuestion!: (question: TaskType) => Promise<void>;
  @testEditor.Action deleteTest!: () => StoreActionType<number>;
  @testEditor.Action addQuestion!: (question: TaskType) => Promise<void>;
  @testEditor.Action deleteQuestion!: (questionId: number) => Promise<void>;
  @testEditor.Action setTestStatus!: (
    payload: SetStatusDataType
  ) => Promise<void>;

  toggleShowDraftModal(): void {
    this.showDraftModal = !this.showDraftModal;
  }

  toggleShowActiveModal(): void {
    this.showActiveModal = !this.showActiveModal;
  }

  toggleShowCloneTestModal(): void {
    this.showCloneTestModal = !this.showCloneTestModal;
  }

  toggleShowEditModal(data: TaskType): void {
    this.editQuestion = data;
    this.showEditQuestionModal = true;
  }

  toggleDeleteQuestionModal(): void {
    this.showDeleteQuestionModal = !this.showDeleteQuestionModal;

    if (this.showDeleteQuestionModal) {
      this.showEditQuestionModal = false;
    }
  }

  toggleDeleteTestModal(): void {
    this.showDeleteTestModal = !this.showDeleteTestModal;
  }

  closeEditPanel(): void {
    this.showEditQuestionModal = false;
    this.editQuestion = TASK_EMPTY_DATA;
  }

  setPageNumber(): void {
    this.$nextTick(() => {
      const { testPage } = this.$refs;
      if (testPage !== undefined) {
        (testPage as any).$el.scrollIntoView();
      }
    });
  }

  async setStatus(status: string): Promise<void> {
    await this.setTestStatus({ id: this.$route.params.id, status });
    await this.getTestSettings();

    this.showDraftModal = false;
    this.showActiveModal = false;
  }

  async sendRequestCloneActiveTest(): Promise<void> {
    const { data } = await this.cloneActiveTest();
    await this.$router.push(`${app_routes.test_edit.slug}/${data}`);
    await this.getTestSettings();

    this.showCloneTestModal = false;
  }

  async sendDeleteQuestion(): Promise<void> {
    await this.deleteQuestion(this.editQuestion.id as number);
    this.toggleDeleteQuestionModal();
  }

  async sendUpdateQuestion(): Promise<void> {
    if (this.editQuestion.id) {
      await this.updateQuestion(this.editQuestion);
    } else {
      await this.addQuestion(this.editQuestion);
    }

    if (!Object.keys(this.validateErrors).length) {
      this.showEditQuestionModal = false;
    }
  }

  async sendDeleteTest(): Promise<void> {
    const id = await this.deleteTest();

    if (id) {
      await this.$router.push({
        name: app_routes.admin_home.name,
      });
    }
  }
}
