import * as STATUSES from "@/constants/testStatuses";
import { TestStatusConfigType } from "@/types/TestStatusConfigType";
import { ObjectOf } from "@/types/ObjectOf";

export const TEST_STATUS_CONFIG: ObjectOf<TestStatusConfigType> = {
  [STATUSES.STATUS_ACTIVE]: {
    slug: STATUSES.STATUS_ACTIVE_SLUG,
    class: "color-green text-bold",
    rowClass: "bg-green",
    testStatusClass: "green",
  },
  [STATUSES.STATUS_DRAFT]: {
    slug: STATUSES.STATUS_DRAFT_SLUG,
    class: "color-gray",
    rowClass: "",
    testStatusClass: "gray",
  },
  [STATUSES.STATUS_ARCHIVED]: {
    slug: STATUSES.STATUS_ARCHIVED_SLUG,
    class: "",
    rowClass: "",
    testStatusClass: "gray",
  },
};
