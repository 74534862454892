import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { TEST_SETTINGS_MODULE } from "@/constants/storeNames";
import { TestSettingsType } from "@/types/API/TestSettingsType";

const testSettings = namespace(TEST_SETTINGS_MODULE);

@Component
export class QuestionKitsMixin extends Vue {
  @testSettings.State testSettings!: TestSettingsType;
  @testSettings.Getter hasSettings!: boolean;
  @testSettings.Getter hasActiveVersion!: boolean;
  @testSettings.Action getTestSettings!: () => Promise<void>;
}
